import { AthleteProfileModel } from "@/models/athlete/AthleteProfileModel";
import { FrontEndModel } from "@/models/FrontEndModel";
import { Comparison } from "@/../types/interfaces";
import { OrganizationPlayerTeam } from "@/../types/interfaces/OrganizationPlayerTeam";
import { OrganizationOnTeam } from '../OrganizationOnTeam';
import { AthleteAssessmentDataModel } from "@/models/athlete/AthleteAssessmentDataModel";
import { SelfAssessmentModel } from "@/models/athlete/SelfAssessmentModel";
import { ScoutingReportModel } from '../../scoutingReport/ScoutingReportModel';
import { BaseSoccerPosition } from '@/../types/enums/sports';
import { AgeGroup, Gender, Under13AgeGroup } from '@best-athletes/ba-types';
import { ageToAgeGroup } from "@/../types";

export class OrganizationTeam {
	id: string;
	name: string;
}
export class OrganizationAssessment{
	id: string;
	twentyToThirtyFiveMeterSplit: number;
	tenMeterSprint: number;
	counterMovementJumpHeight: number;
	reactiveStrengthIndex: number;
	yoyoIntermittentRecoveryTestStage: number;
}
export class OrganizationScouting{
	id: string;

	technical: number;
	tactical: number;
	physical: number;
	mental: number;

	attackingOrganization: number;
	attackingTransition: number;
	attackingSetPlays: number;
	defendingOrganization: number;
	defendingTransition: number;
	defendingSetPlays: number;

	competitive: number;
	resilience: number;
	intelligence: number;
	speed: number;
	presence: number;
}
export class OrganizationMindset{
	id: string;

	overall: number;
	archetype: string;

	mentalToughness: number;
	composure: number;
	confidence: number;
	drive: number;
	grit: number;

	mentalExecution: number;
	adaptability: number;
	decisiveness: number;
	focusSpeed: number;

	coachability: number;
	attentionToDetail: number;
	conventionality: number;
	growthMindset: number;
	informationProcessing: number;
	
	hittingMindset: number;
	leadershipRating: number;
	pressurePerformer: number;
}
export class OrganizationPlayerModel {
	id: string;
	firstName: string;
	lastName: string;
	Age: number;
	gender: string = "F";
	primaryPosition: BaseSoccerPosition;
	anticipatedStudy: string = "";
	teams: OrganizationTeam[] | null;
	lastAssessment: OrganizationAssessment | null = null;
	lastAssessmentComparison: Comparison | null = null;
	scoutingReport: OrganizationScouting | null = null;
	mindsetReport: OrganizationMindset | null = null;
};